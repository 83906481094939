export const actionTypes = {
  ACCOUNT: {
    CANDIDATE_ACCOUNT_TAB: "CANDIDATE_ACCOUNT_TAB"
  },
  CANDIDATE: {
    CANDIDATE_PROFILE_GET: "CANDIDATE_PROFILE_GET"
  },
  RESET_STATES: {
    RESET_ACCOUNT_STATE: "RESET_ACCOUNT_STATE"
  },
  JOB: {
    JOB_CREATE: "JOB_CREATE",
    SET_JOB_CATEGORY: "SET_JOB_CATEGORY",
    SET_JOB: "SET_JOB"
  },
  USER: {
    USER_LIST: "USER_LIST"
  }
};
