import {
    actionTypes
} from '../Config/ActionTypes';

const {
    CANDIDATE,
    RESET_STATES
} = actionTypes;
const {
    CANDIDATE_PROFILE_GET
} = CANDIDATE;

const INITIAL_STATE = {
    candidate: null
};
export default (state = INITIAL_STATE, action) => {
    const {
        payload
    } = action;
    switch (action.type) {
        case RESET_STATES:
            return {
                ...state,
                ...INITIAL_STATE
            };
        case CANDIDATE_PROFILE_GET:
            return {
                ...state,
                candidate: payload
            };
        default:
            return state;
    }
};