import { actionTypes } from "../Config/ActionTypes";

const { USER, RESET_STATES } = actionTypes;
const { USER_LIST } = USER;

const INITIAL_STATE = {
  users: null
};
export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case RESET_STATES:
      return {
        ...state,
        ...INITIAL_STATE
      };
    case USER_LIST:
      return {
        ...state,
        users: payload
      };
    default:
      return state;
  }
};
