import axios from 'axios';
import { Url } from './ConfigApi';
import WebStorage from './Webstorage';
// import {
//     appStore
// } from "../config";
import { WebStorageNames } from './Utils';
//import { actionTypes } from "./ActionTypes";

const baseURL = Url.ROOT_URL;
export default class AjaxService {
  static setDefaults() {
    const authInfo = WebStorage.getLocalStore(WebStorageNames.Token);
    if (authInfo) {
      const AUTH_TOKEN = 'Bearer '.concat(authInfo);
      axios.defaults.headers.common.Authorization = AUTH_TOKEN;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
    axios.defaults.baseURL = baseURL;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.post.Accept = 'application/json';
    axios.defaults.headers.pragma = 'no-cache';

    axios.defaults.withCredentials = true;
    axios.defaults.timeout = 60000; // milliseconds
  }

  static transformResponse() {
    return {
      transformResponse: [data => JSON.parse(data)]
    };
  }

  static handleResponse(callback, response) {
    //appStore.dispatch({ type: actionTypes.LOADED, payload: true });
    if (typeof callback === 'function') {
      callback(response);
    }
  }

  static handleExceptions(response) {
    if (
      response &&
      response.response &&
      (response.response.status === 401 || response.response.status === 403)
    ) {
      this.logout();
    }

    //appStore.dispatch({ type: actionTypes.LOADED, payload: true });
  }

  static post(api, params, callback, auth = false) {
    const me = this;
    if (auth) {
      me.setDefaults();
    }
    try {
      //appStore.dispatch({ type: actionTypes.LOADED, payload: false });
      axios
        .post(api, params, this.transformResponse())
        .then(result => {
          me.handleResponse(callback, result);
        })
        .catch(ex => {
          me.handleExceptions(ex);
        });
    } catch (ex) {
      me.handleExceptions(ex);
    }
  }

  static get(api, params, callback) {
    const me = this;
    me.setDefaults();
    try {
      //appStore.dispatch({ type: actionTypes.LOADED, payload: false });
      axios
        .get(api, params, this.transformResponse())
        .then(result => {
          me.handleResponse(callback, result);
        })
        .catch(ex => {
          me.handleExceptions(ex);
        });
    } catch (ex) {
      me.handleExceptions(ex);
    }
  }

  static async delete(api, params, callback) {
    const me = this;
    //appStore.dispatch({ type: actionTypes.LOADED, payload: false });
    await axios
      .delete(api, params, this.transformResponse())
      .then(response => {
        me.handleResponse(callback, response);
      })
      .catch(e => {
        me.handleExceptions(e);
      });
  }

  static put(api, params, callback, auth = true) {
    const me = this;
    if (auth) {
      me.setDefaults();
    }
    try {
      //appStore.dispatch({ type: actionTypes.LOADED, payload: false });
      axios
        .put(api, params, this.transformResponse())
        .then(result => {
          me.handleResponse(callback, result);
        })
        .catch(ex => {
          me.handleExceptions(ex);
        });
    } catch (ex) {
      me.handleExceptions(ex);
    }
  }

  static logout() {
    WebStorage.removeLocalStore(WebStorageNames.UserInfo);
    window.location.reload(true);
  }
}
