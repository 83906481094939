import { actionTypes } from '../Config/ActionTypes';

const { JOB, RESET_STATES } = actionTypes;
const { JOB_CREATE, SET_JOB_CATEGORY, SET_JOB } = JOB;

const INITIAL_STATE = {
  candidate: null,
  CategoryId: null,
  SelectedJob: null
};
export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case RESET_STATES:
      return {
        ...state,
        ...INITIAL_STATE
      };
    case JOB_CREATE:
      return {
        ...state,
        candidate: payload
      };
    case SET_JOB_CATEGORY:
      return {
        ...state,
        CategoryId: payload
      };
    case SET_JOB:
      return {
        ...state,
        SelectedJob: payload
      };
    default:
      return state;
  }
};
