import { filter, find } from "lodash";
import { VideoTypes, JobBenefits, JobTypes } from "./Utils";

export class Common {
  static subtituteString() {
    let s = this,
      i = arguments.length;

    while (i--) {
      s = s.replace(new RegExp(`\\{${i}\\}`, "gm"), arguments[i]);
    }
    return s;
  }
  static getYears = () => {
    const year = 2000;
    const years = Array.from(new Array(20), (val, index) => index + year);
    return years;
  };
}
export const getDropList = () => {
  const year = new Date().getFullYear();
  return Array.from(new Array(50), (v, i) => (
    // eslint-disable-next-line react/react-in-jsx-scope
    <option key={i} value={year + i}>
      {year + i}
    </option>
  ));
};
export const isBenefitExist = (arrar, value) => {
  const list = filter(arrar, o => o[0] === value);
  return list && list.length > 0 ? true : false;
};
export const isEmployeeBenefitExist = (arrar, value) => {
  const list = filter(arrar, o => o === value);
  return list && list.length > 0 ? true : false;
};
export const parseDate = timeStamp => {
  const currentDate = new Date(Date.parse(timeStamp));
  const date = currentDate.getDate();
  const month = currentDate.getMonth(); // January is 0 not 1
  const year = currentDate.getFullYear().toString();
  return `${date}/${month + 1}/${year.substring(2, 4)}`;
};
export const getVideoCategoryName = value => {
  const video = find(VideoTypes, o => o.value === value.toString());
  return video && video.label;
};
export const getBenefitsName = value => {
  const benefits = find(JobBenefits, o => o.value === value);
  return benefits && benefits.label;
};
export const isCategoryExist = (array, value) => {
  const v = find(array, o => o === value);
  if (v) return true;
  return false;
};
export const selectedJobType = value => {
  const v = find(JobTypes, o => o.value === value.toString());
  return v;
};
