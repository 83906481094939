import { combineReducers } from 'redux';
import AccountReducer from '../Reducers/AccountReducer';
import CandidateReducer from '../Reducers/CandidateReducer';
import JobReducer from '../Reducers/JobReducer';
import CompanyReducer from '../Reducers/CompanyReducer';
import UserReducer from '../Reducers/UserReducer';

export default combineReducers({
  accountReducer: AccountReducer,
  candidateReducer: CandidateReducer,
  jobReducer: JobReducer,
  companyReducer: CompanyReducer,
  userReducer: UserReducer
});
