import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import Loadable from "react-loadable";
import { createStore, applyMiddleware } from "redux";
import CombineReducers from "./Config/CombineReducers";
import ReduxThunk from "redux-thunk";
import { Provider } from "react-redux";
import { Common } from "./Config/CommonFunctions";
import AjaxService from "./Config/Api";
import "./App.scss";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import("./views/Pages/Login"),
  loading
});

const Register = Loadable({
  loader: () => import("./views/Pages/Register"),
  loading
});

const Page404 = Loadable({
  loader: () => import("./views/Pages/Page404"),
  loading
});

const Page500 = Loadable({
  loader: () => import("./views/Pages/Page500"),
  loading
});
export const appStore = createStore(
  CombineReducers,
  {},
  applyMiddleware(ReduxThunk)
);
class App extends Component {
  constructor() {
    super();
    AjaxService.setDefaults();
    this.initializeApp();
  }
  initializeApp() {
    String.prototype.format = Common.subtituteString;
  }
  render() {
    return (
      <Provider store={appStore}>
        <Router>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route
              exact
              path="/register"
              name="Register Page"
              component={Register}
            />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
