export const S3 = "https://s3-eu-west-1.amazonaws.com/rfr-cv/";

export const emailPattern = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordReg = /^.*(?=.{6,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/;
export const numberReg = /[+-]?([0-9]*[.])?[0-9]+/;
export const urlReg = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

export const Messages = {
  validation: {
    emailFormat: "That doesn't look like a valid email, have another go. \n",
    emailRequired: "Email is required. \n",
    oldPasswordRequired: "Old Password is required. \n",
    newPasswordRequired: "New Password is required. \n",
    companyNameRequired: "Company name is required. \n",
    jobTitleRequired: "Job title  is required. \n",
    jobRoleRequired: "Job role  is required. \n",
    professionRequired: "Profession is required. \n",
    firstNameRequired: "First Name is required. \n",
    languagesRequired: "Languages are required. \n",
    lastNameRequired: "Last tName is required. \n",
    nationalityRequired: "Nationality is required. \n",
    genderRequired: "Gender is required. \n",
    phoneRequired: "Phone is required. \n",
    stateRequired: "State is required. \n",
    countryRequired: "Country is required. \n",
    postCodeRequired: "POST/ZIP CODE is required. \n",
    addressRequired: "Address is required. \n",
    cityRequired: "City is required. \n",
    passwordRequired: "Password is required. \n",
    passwordLength: "Your password must be 8 or more characters long. \n",
    passwordFormat:
      "Your password must contain at least 1 uppercase, lowercase, number and special character. \n",
    locationRequired: "Location is required. \n",
    minSalaryRequired: "Min Salary is required. \n",
    shortDetailRequired: "Short Detail is required. \n",
    detailRequired: "Detail is required. \n",
    numberAllowed: "Only numbers are allowed. \n",
    invalidUrl: "Please provide valid url. \n",
    videoUrl: "Please provide video url. \n"
  }
};
export const WebStorageNames = {
  RFRAdminInfo: "rfrAdminInfo",
  AuthInfo: "authInfo",
  Token: "token",
  CompletedExercise: "completedExercise"
};
export const Roles = {
  Candidate: "Candidate",
  Admin: "Admin",
  Employer: "Employer"
};
export const Months = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" }
];
export const Years = [
  { value: "2010", label: "2010" },
  { value: "2011", label: "2011" },
  { value: "2012", label: "2012" },
  { value: "2013", label: "2013" },
  { value: "2014", label: "2014" },
  { value: "2015", label: "2015" },
  { value: "2016", label: "2016" },
  { value: "2017", label: "2017" },
  { value: "2018", label: "2018" },
  { value: "2019", label: "2019" },
  { value: "2020", label: "2020" }
];

export const Types = [
  { value: "1", label: "A-level" },
  { value: "2", label: "GCSE / Secondary School" },
  { value: "3", label: "Master's degree" },
  { value: "4", label: "PhD" },
  { value: "5", label: "University degree" }
];

export const Grades = [
  { value: "1", label: "A*" },
  { value: "2", label: "A" },
  { value: "3", label: "B" },
  { value: "4", label: "C" },
  { value: "5", label: "D" },
  { value: "6", label: "E" }
];
export const CompanyType = [
  { value: "1", label: "Direct employer" },
  { value: "2", label: "Charity / not for profit" },
  { value: "3", label: "Recruitment consultancy" }
];
export const Industries = [
  { value: '1', label: 'Health' },
  { value: '2', label: 'Education' },
  { value: '3', label: 'Engineering' },
  { value: '4', label: 'Emiratization' },
  { value: '5', label: 'Technology' }
];

export const CompanySize = [
  { value: "1", label: "Not known" },
  { value: "2", label: "1-10" },
  { value: "3", label: "11-50" },
  { value: "4", label: "51-200" },
  { value: "5", label: "201-500" },
  { value: "6", label: "501-1000" },
  { value: "7", label: "1001-5000" },
  { value: "8", label: "5001-10000" },
  { value: "9", label: "10001+" }
];
export const JobTypes = [
  { value: "1", label: "Full Time" },
  { value: "2", label: "Temporary" },
  { value: "3", label: "Contract" },
  { value: "4", label: "Permanent" },
  { value: "5", label: "Part Time" }
];

export const JobBenefits = [
  { value: "1", label: "Tax free Salary" },
  { value: "2", label: "Career Progression" },
  { value: "3", label: "Medical Insurance" },
  { value: "4", label: "Accommodation" },
  { value: "5", label: "Mal Practise (for Medical Professionals)" },
  { value: "6", label: "Medical Insurance for Spouse & Children up" },
  { value: "7", label: "Free Education for children up to" }
];
export const Benefits = [
  "Tax free Salary",
  "Career Progression",
  "Medical Insurance",
  "Accommodation",
  "Mal Practise (for Medical Professionals)"
];

export const VideoTypes = [
  { value: "1", label: "Vimeo" },
  { value: "2", label: "Video" }
];

export const JobStatus = {
  Publish: 1,
  Draft: 2
};

export const SalaryRange = [
  { value: "", label: "Any value" },
  { value: "10000", label: "AED 10,000" },
  { value: "12000", label: "AED 12,000" },
  { value: "14000", label: "AED 14,000" },
  { value: "16000", label: "AED 16,000" },
  { value: "18000", label: "AED 18,000" },
  { value: "20000", label: "AED 20,000" },
  { value: "22000", label: "AED 22,000" },
  { value: "24000", label: "AED 24,000" }
];

const usersData = [
  {
    id: 0,
    name: "John Doe",
    registered: "2018/01/01",
    role: "Guest",
    status: "Pending"
  },
  {
    id: 1,
    name: "Samppa Nori",
    registered: "2018/01/01",
    role: "Member",
    status: "Active"
  },
  {
    id: 2,
    name: "Estavan Lykos",
    registered: "2018/02/01",
    role: "Staff",
    status: "Banned"
  },
  {
    id: 3,
    name: "Chetan Mohamed",
    registered: "2018/02/01",
    role: "Admin",
    status: "Inactive"
  },
  {
    id: 4,
    name: "Derick Maximinus",
    registered: "2018/03/01",
    role: "Member",
    status: "Pending"
  },
  {
    id: 5,
    name: "Friderik Dávid",
    registered: "2018/01/21",
    role: "Staff",
    status: "Active"
  },
  {
    id: 6,
    name: "Yiorgos Avraamu",
    registered: "2018/01/01",
    role: "Member",
    status: "Active"
  },
  {
    id: 7,
    name: "Avram Tarasios",
    registered: "2018/02/01",
    role: "Staff",
    status: "Banned"
  },
  {
    id: 8,
    name: "Quintin Ed",
    registered: "2018/02/01",
    role: "Admin",
    status: "Inactive"
  },
  {
    id: 9,
    name: "Enéas Kwadwo",
    registered: "2018/03/01",
    role: "Member",
    status: "Pending"
  },
  {
    id: 10,
    name: "Agapetus Tadeáš",
    registered: "2018/01/21",
    role: "Staff",
    status: "Active"
  },
  {
    id: 11,
    name: "Carwyn Fachtna",
    registered: "2018/01/01",
    role: "Member",
    status: "Active"
  },
  {
    id: 12,
    name: "Nehemiah Tatius",
    registered: "2018/02/01",
    role: "Staff",
    status: "Banned"
  },
  {
    id: 13,
    name: "Ebbe Gemariah",
    registered: "2018/02/01",
    role: "Admin",
    status: "Inactive"
  },
  {
    id: 14,
    name: "Eustorgios Amulius",
    registered: "2018/03/01",
    role: "Member",
    status: "Pending"
  },
  {
    id: 15,
    name: "Leopold Gáspár",
    registered: "2018/01/21",
    role: "Staff",
    status: "Active"
  },
  {
    id: 16,
    name: "Pompeius René",
    registered: "2018/01/01",
    role: "Member",
    status: "Active"
  },
  {
    id: 17,
    name: "Paĉjo Jadon",
    registered: "2018/02/01",
    role: "Staff",
    status: "Banned"
  },
  {
    id: 18,
    name: "Micheal Mercurius",
    registered: "2018/02/01",
    role: "Admin",
    status: "Inactive"
  },
  {
    id: 19,
    name: "Ganesha Dubhghall",
    registered: "2018/03/01",
    role: "Member",
    status: "Pending"
  },
  {
    id: 20,
    name: "Hiroto Šimun",
    registered: "2018/01/21",
    role: "Staff",
    status: "Active"
  },
  {
    id: 21,
    name: "Vishnu Serghei",
    registered: "2018/01/01",
    role: "Member",
    status: "Active"
  },
  {
    id: 22,
    name: "Zbyněk Phoibos",
    registered: "2018/02/01",
    role: "Staff",
    status: "Banned"
  },
  {
    id: 23,
    name: "Einar Randall",
    registered: "2018/02/01",
    role: "Admin",
    status: "Inactive"
  },
  {
    id: 24,
    name: "Félix Troels",
    registered: "2018/03/21",
    role: "Staff",
    status: "Active"
  },
  {
    id: 25,
    name: "Aulus Agmundr",
    registered: "2018/01/01",
    role: "Member",
    status: "Pending"
  },
  {
    id: 42,
    name: "Ford Prefex",
    registered: "2001/05/21",
    role: "Alien",
    status: "Don't panic!"
  }
];

export default usersData;
