import { actionTypes } from '../Config/ActionTypes';

const { JOB, RESET_STATES } = actionTypes;
const { JOB_CREATE } = JOB;

const INITIAL_STATE = {
  candidate: null
};
export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case RESET_STATES:
      return {
        ...state,
        ...INITIAL_STATE
      };
    case JOB_CREATE:
      return {
        ...state,
        candidate: payload
      };
    default:
      return state;
  }
};
