export const Url = {
  ROOT_URL: "https://api.redfordrecruiters.com/api/",
  //ROOT_URL: "http://localhost:50114/api/",
  REST_APIs: {
    Account: {
      LogIn: "AccountApi/AdmimLogin",
      Create: "Candidate/CreateCanidate",
      Register: "AccountApi/CreateCanidate",
      Profile: "Candidate/Profile",
      UploadProfileImage: "Candidate/UploadImage",
      Post: "Candidate/Post",
      Put: "Candidate/Put",
      Languages: "Candidate/Languages",
      CoverLetter: "Candidate/CoverLetter",
      Licenses: "Candidate/Licenses"
    },
    Candidate: {
      GetAll: "Candidate/GetAll",
      Profile: "Candidate/Profile?userId={0}",
      Add: "CandidateExperience/Post",
      Put: "Candidate/Put",
      Delete: "CandidateExperience/Delete/?id={0}",

      Experience: {
        Add: "CandidateExperience/Post",
        Put: "CandidateExperience/Put",
        Delete: "CandidateExperience/Delete/?id={0}",
        UploadData: "Candidate/UploadCV"
      },
      Qualification: {
        Add: "CandidateQualification/Post",
        Put: "CandidateQualification/Put",
        Delete: "CandidateQualification/Delete/?id={0}"
      }
    },
    Recuiter: {
      Account: {
        Register: "AccountApi/CreateCompany",
        LogIn: "AccountApi/Login"
      },
      Job: {
        Post: "JobApi/Create",
        Put: "JobApi/Put",
        GetAllPaged: "JobApi/GetAllPaged/?page={0}",
        GetById: "JobApi/GetById/?id={0}",
        GetByGuId: "JobApi/GetByGuId/?guId={0}",
        Get: "JobApi/Get",
        GetJobsByActiveStatus: "JobApi/GetJobsByActiveStatus?userId={0}",
        GetJobsByDraft: "JobApi/GetJobsByDraft?userId={0}",
        GetJobsByCloseDate: "JobApi/GetJobsByCloseDate?userId={0}",
        GetJobsByFilter:
          "JobApi/GetJobsByFilter?startRange={0}&endRange={1}&categories={2}"
      },
      Company: {
        GetProfile: "Company/CompanyProfile?guId={0}",
        UpdateOverview: "Company/Overview",
        UploadLogo: "Company/Logo",
        UpdateSocialMedia: "Company/SocialMedia",
        UpdateBenefits: "Company/Benefits",
        AddVideo: "CompanyVideo/Add",
        UpdateVideo: "CompanyVideo/Update",
        DeleteVideo: "CompanyVideo/Delete?id={0}",
        UploadBanner: "Company/Banner",
        AddMedia: "CompanyMedia/Add",
        DeleteMedia: "CompanyMedia/Delete?id={0}",
        GetAllCompanies: "Company/Companies",
        GetByGuId: "Company/GetByGuId?guId={0}"
      }
    },
    JobApplicant: {
      JobApplicantAdd: "JobApplicant/ApplyJob",
      GetJob: "JobApplicant/GetJob?userId={0}&jobId={1}"
    }
  }
};
