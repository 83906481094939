import { actionTypes } from '../Config/ActionTypes';

const { ACCOUNT } = actionTypes;
const { CANDIDATE_ACCOUNT_TAB, RESET_STATES } = ACCOUNT;

const INITIAL_STATE = {
  tab: '1'
};
export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case RESET_STATES:
      return {
        ...state,
        ...INITIAL_STATE
      };
    case CANDIDATE_ACCOUNT_TAB:
      return {
        ...state,
        tab: payload
      };
    default:
      return state;
  }
};
